.table {
  table {
    border-spacing: 10px;
    tbody {
      tr {
        box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 6px 0px;
        border-radius: 8px;
      }
    }
  }
}
.box {
  background: #fff;
  padding: 20px;
  border-radius: 13px;
  margin-bottom: 20px;

  .title {
    font-weight: 700;
    font-size: 18px;
  }
}
.noBottomMargin {
  margin-bottom: 0;
}
.yearRatio {
  .title {
    text-align: center;
    font-weight: 700;
  }
}