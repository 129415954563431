

.right {
    height: 44px;
    line-height: 20px;
    border-radius: 13px;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
}

.loopBox{
    display: flex;
}