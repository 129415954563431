

@import '../../../../../index.scss';

.detailWrapper {
  padding: 70px;
}

.pane {
  margin-top: 50px;
  background-color: #fff;
  border-radius: 10px;
  padding: 30px 50px 100px;
  .caseList {
    .header {
      margin-bottom: 40px;
      .title {
        margin-bottom: 40px;
        font-size: 24px;
        font-weight: 700;
        .judge {
          color: textRed;
        }
        .lawyer {
          color: $green;
        }
      }
    }
    .caseListWrapper {
      .list {
        font-size: 18px;
        .item {
          .caseContent {
            color: #000;
            .title {
              font-size: 28px;
            }
            .abstract {
              margin-bottom: 40px;
            }
            .info {
              display: flex;
              .lawyer {
                color: $green;
              }
            }
          }
        }
      }
    }
  }
  .caseDetail {
    .title {
      font-size: 28px;
      text-align: center;
    }
    .content {
      font-size: 18px;
    }
  }
}
