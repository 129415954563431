.resultWrapper {
  padding: 0 50px;
  text-align: left;
  .title {
    font-size: 24px;
    margin: 30px 0;
  }
  .desc {
    font-size: 24px;
    width: 934px;
    margin: auto;
    .evaluation {
      cursor: pointer;
      border: none;
      margin: 0;
      width: 437px;
      height: 101px;
      p {
        margin: 0;
        line-height: 1.7;
        font-size: 14px;
      }
      .fontLarge {
        font-weight: 700;
        font-size: 24px;
      }
    }
    .descPara {
      margin: 0;
      line-height: 1.8;
    }
  }
  .trendChart {
    .title {
      font-size: 20px;
      font-weight: 700;
    }
  }
}

.image {
  width: 100%;
  height: 200px;
  margin-bottom: 20px;
}