
@import '../../../../../index.scss';

.contentWrapper {
  padding-bottom: 50px;
  .content {
    :global {
      .ant-tabs-nav {
        &::before {
          display: none;
        }
        .ant-tabs-tab {
          border-radius: 8px;
          height: 69px;
          border: 0;
          padding: 0 45px;
          .ant-tabs-tab-btn {
            width: 100%;
            font-size: 24px;
          }
        }
        .ant-tabs-tab-active {
          background-color: $textRed;
          .ant-tabs-tab-btn {
            color: #fff;
          }
        }
      }
    }
  }
}

.detailWrapper {
  padding: 70px;
}