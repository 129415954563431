@import '../../../../index.scss';

.panel {
  min-width: 1290px;
  font-size: 16px;
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 65px 300px;
  .header {
    .title {
      font-weight: 700;
      font-size: 24px;
      color: rgb(16, 16, 16);
      line-height: 36px;
      margin-bottom: 32px;
    }
  }
  .cardItem {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 6px 0px;
    border-radius: 10px;
    margin: 30px 0;
    cursor: pointer;
    color: #000;
  }
}

.paneHeader {
  .rightInfo {
    float: left;
    font-size: 18px;
    color: $textRed;
    line-height: 17px;
    display: flex;
    margin-left: 100px;
  }
}