@import '../../../../index.scss';
.card {
  background-color: #fff;
  width: 810px;
  height: 230px;
  border-radius: 8px;
  // padding: 50px 130px;
  position: relative;
  .avatar {
    position: absolute;
    top: -40px;
    right: 180px;
    background-color: #fff;
    padding: 10px;
    border: 1px solid $blue;
  }
  .infoWrapper {
    font-size: 20px;
    height: 100%;
    .left {
      .title {
        display: flex;
        .name {
          font-size: 36px;
          font-weight: 900;
        }
        .pos {
          color: #fff;
          height: 36px;
          line-height: 36px;
          margin-top: 20px;
          padding: 0 20px;
          border-radius: 4px;
          text-align: center;
          font-size: 20px;
        }
        .judge {
          background-color: #a14244;
        }
        .lawyer {
          background-color: $green;
        }
      }
      .personBox {
        padding: 50px 120px;
        line-height: 2;
        .pos {
          margin-left: 30px;
        }
      }
      .locationBox {
        height: 100%;
        padding: 0 50px;
        .title {
          flex-direction: column;
          justify-content: center;
          height: 100%;
        }
      }
    }
    .right {
      .rightInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        .line {
          line-height: 36px;
          margin-top: 8px;
          .highlight {
            font-weight: 700;
            font-size: 36px;
            margin: 0 10px;
          }
          .red {
            color: rgba(161, 66, 68, 1);
          }
          .green {
            color: $green;
          }
        }
      }
    }
  }
}

.chart {
  .title {
    font-size: 16px;
    color: #b8bac2;
    text-align: center;
  }
}

.navMenu {
  float: left;
  display: flex;
  width: 384px;
  height: 60px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 6px 0px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 60px;
  .navMenuItem {
    display: flex;
    flex: 1;
    align-items: center;
    max-width: 126px;
    width: 126px;
    text-align: center;
    justify-content: center;
    .textOverOne {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .navMenuItemActive {
    color: $blue;
  }
}
.menuList {
  text-align: center;
  border-radius: 8px;
  .menuItemActive {
    color: $blue;
  }
}