@import '../../../../index.scss';

.list {
  .item {
    .content {
      display: flex;
      width: 100%;
      .left {
        line-height: 55px;
        font-size: 16px;
        width: 384px;
        .title {
          text-align: right;
          color: rgb(184, 186, 194);
        }
        .order {
          font-size: 20px;
          color: #000;
        }
        .info {
          text-align: left;
        }
        .primary {
          font-size: 20px;
          color: $blue;
        }
        .btn {
          height: 30px;
          border-radius: 4px;
          font-size: 14px;
        }
        .blueBtn {
          border: 1px solid $blueBtn;
          color: $blueBtn;
        }
        .redBtn {
          border: 1px solid $textRed;
          color: $textRed;
        }
      }
      .right {
        flex: 1;
      }
    }
  }
}