.autoImg{
  width: 100%;
}

.registerForm{
  padding: 20px 0;
  margin-top: 10px;
}

.carouselBox{
  width: 60%;
}
.sliderBox{
  width: 40%;
}
.announce{
  margin-top: 20px;
  color: #74809b;
  font-size: 14px;
  line-height: 1.57em;
}
.mt10{
  margin-top: 10px;
}

.registerIndex {
  :global {
    .ant-carousel {
      .slick-next::before, .slick-prev::before {
        display: none;
      }
    }
  }
  .cardImg {
    width: 100%;
    height: auto;
    min-width: 450px;
    min-height: 150px;
    max-height: 400px;
  }
}

.payModal {
  .title {
    font-size: 18px;
    font-weight: bold;
    .success {
      margin-right: 30px;
      font-size: 14px;
      color: red;
    }
  }
  .qrcode {
    text-align: center;
    margin-top: 20px;
  }
  .consult {
    margin-top: 15px;
  }
}