@import '../../../../index.scss';

.search {
  padding-bottom: 50px;
  .title {
    margin: 210px 0 168px;
    text-align: center;
    color: #fff;
    font-size: 28px;
  }
  .searchWrapper {
    width: 750px;
    margin: auto;
    .inputWrapper {
      width: 590px;
      height: 58px;
      border-radius: 10px;
      input {
        font-size: 20px;
        text-indent: 10px;
        &::-webkit-input-placeholder {
          color: #333;
        }
        &:-moz-placeholder {
          color: #333
        }
        &:-ms-input-placeholder {
          color: #333;
        }
      }
      .conditionBtn {
        cursor: pointer;
        font-size: 18px;
      }
    }
    button {
      width: 150px;
      height: 60px;
      border-radius: 10px;
      font-size: 20px;
      font-weight: bold;
    }
  }
  .searchRes {
    width: 80%;
    margin: auto;
    .info {
      color: #fff;
      font-size: 18px;
      font-weight: bold;
    }
  }
  .resultList {
    border: 1px solid #2449a8;
    background-color: #fff;
    border-radius: 8px;
  }
  .historyList {
    max-height: 300px;
    overflow-y: scroll;
  }
  .list {
    min-width: 590px;
    padding: 10px 15px;
    font-size: 18px;
    .listItem {
      cursor: pointer;
      &::before {
        content: '';
        display: inline-block;
        margin-right: 10px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: rgb(233, 41, 125);
      }
      p {
        color: #000;
        display: inline-block;
      }
      span {
        color: #000;
        font-weight: bold;
        margin-left: 20px;
      }
    }
    .lawyerItem {
      &::before {
        background-color: $green;
      }
    }
    .lawFirmItem {
      &::before {
        background-color: $blueBtn;
      }
    }
  }
  .err {
    text-align: center;
    color: #fff;
  }
  .historyWrapper {
    position: absolute;
    z-index: 2;
    margin: auto;
    width: 750px;
    left: 0;
    right: 0;
    .history {
      width: 590px;
      margin-top: 20px;
      border-radius: 8px;
      border: 1px solid #2449a8;
      background-color: #fff;
      .footer {
        text-align: right;
        padding: 18px 20px;
        font-size: 18px;
      }
    }
  }
}

.condition {
  font-size: 18px;
  width: 380px;

  .title {
    margin: 10px 0;
  }
}

.font18 {
  font-size: 18px;
}