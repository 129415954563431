.header {
  padding-top: 50px;
  .left {
    padding-left: 125px;
    float: left;
    display: flex;
    a {
      width: 100%;
      height: 60px;
      display: block;
      button {
        width: 150px;
        color: #fff;
        height: 60px;
        font-size: 18px;
      }
      .active {
        font-weight: bold;
        font-size: 20px;
        border: 1px solid red;
      }
    }
  }
  .right {
    width: 340px;
    float: right;
    padding-top: 10px;
    .company {
      font-size: 18px;
      font-weight: bold;
      color: #fff;
    }
  }
}