.ant-carousel .slick-dots li button {
    background: #1890ff;
    opacity: 0.4;
}

.ant-carousel .slick-dots li.slick-active button {
    opacity: 1;
    background: #1890ff;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
    font-size: inherit;
    color: currentColor;
}
